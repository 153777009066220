import { GeoCoordinates, GeoSearchResponse } from '../../types/geoSearchResponse';

type FetchCoordinatesParams = {
  address?: string;
  postCode?: string;
  postLocation?: string;
};

async function fetchCoordinates({
  address,
  postCode,
  postLocation,
}: FetchCoordinatesParams): Promise<GeoCoordinates> {
  const baseUrl = 'https://ws.geonorge.no/adresser/v1/sok?fuzzy=true';
  const params = new URLSearchParams();

  if (postCode) params.append('postnummer', postCode);

  if (address) params.append('adressetekst', address);

  if (postLocation) {
    params.append('poststed', postLocation);
    params.append('sok', postLocation);
  }

  const url = `${baseUrl}&${params.toString()}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }
    const data: GeoSearchResponse = await response.json();
    if (data.adresser.length > 0) {
      const { lat, lon } = data.adresser[0].representasjonspunkt;
      return { latitude: lat, longitude: lon };
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return { latitude: undefined, longitude: undefined };
}

export default fetchCoordinates;
