import { useRouter } from 'next/router';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiSearch } from 'react-icons/fi';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import ButtonGroup, { BUTTON_GROUP } from '../../components/dealerPage/ButtonGroup';
import ListView from '../../components/dealerPage/ListView';
import ResizablePanel from '../../components/form/containers/ResizablePanel';
import DealersPageQuery from '../../groq/pages/dealerPage';
import { sanityClient } from '../../lib/sanity.server';
import fetchCoordinates from '../../sanity/utils/fetchGeoLocation';
import { DealerForSearchType, MenuType, SEOType, SettingsType } from '../../types';
import { GeoCoordinates } from '../../types/geoSearchResponse';
import getAllQueryParameters from '../../utils/queryParams';

interface PageProps {
  data: {
    content: {
      overline?: string;
      title: string;
      seo?: SEOType;
      defaultSEO: { title: string };
    };
    dealers: DealerForSearchType[];
    menus: MenuType;
    settings: SettingsType;
    services: { _id: string; title: string }[];
    carBrands: { _id: string; title: string }[];
    serviceCarBrands: { _id: string; title: string }[];
    usedCars: { _id: string; title: string }[];
  };
}
interface SearchState {
  searchText: string;
  searchService: string;
  searchBrand: string;
  searchCentre: string;
}

function DealersPage({ data }: PageProps) {
  const {
    menus,
    dealers,
    content: { overline, title, seo, defaultSEO },
    settings,
    services,
    carBrands,
    serviceCarBrands,
  } = data;

  const router = useRouter();
  const { query, pathname } = router;
  const inputRef = useRef(null);
  const [searchState, setSearchState] = useState<SearchState>({
    searchText: (router.query.q as string) || '',
    searchService: (router.query.s as string) || '',
    searchBrand: (router.query.b as string) || '',
    searchCentre: (router.query.c as string) || '',
  });
  const [geoCoordinates, setGeoCoordinates] = useState<GeoCoordinates | null>(null);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!router.isReady) return;
    const queryParams = getAllQueryParameters();
    setSearchState({
      searchText: queryParams?.q === 'Alle' ? '' : (queryParams?.q as string) || '',
      searchService: queryParams?.s || 'Alle',
      searchBrand: queryParams?.b || 'Alle',
      searchCentre: queryParams?.c || 'Alle',
    });
  }, [router.isReady]);

  const handleSearch: ChangeEventHandler = async (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setSearchState((prevState) => ({ ...prevState, [name]: value }));
    const queryParameterName = () => {
      switch (name) {
        case 'searchText':
          return 'q';
        case 'searchBrand':
          return 'b';
        case 'searchService':
          return 's';
        case 'searchCentre':
          return 'c';
        default:
          return '';
      }
    };
    const queryValue = value === '' && queryParameterName() === 'q' ? 'Alle' : value;

    router.replace(
      {
        pathname,
        query: {
          ...query,
          [queryParameterName()]: queryValue,
        },
      },
      undefined,
      { shallow: true },
    );

    if (name === 'searchText') {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const coordinates = await (async () => {
          if (value.length >= 1 && Number.isNaN(Number(value))) {
            // Fetch coordinates for postal place
            return fetchCoordinates({ postLocation: value });
          }
          if (value.length === 4 && !Number.isNaN(Number(value))) {
            // Fetch coordinates for postal number
            return fetchCoordinates({ postCode: value });
          }
          return undefined;
        })();

        if (coordinates?.latitude !== undefined && coordinates?.longitude !== undefined) {
          setGeoCoordinates(coordinates);
        } else {
          setGeoCoordinates(null);
        }
      }, 300); // 300ms debounce delay

      setDebounceTimeout(newTimeout);
    }
  };

  const matchBrandSearch = (dealer: DealerForSearchType) => {
    if (searchState.searchBrand === 'Alle') {
      return true;
    }
    if (searchState.searchBrand === 'usedCars') {
      return dealer.relatedUsedCarDealers && dealer.relatedUsedCarDealers.length > 0;
    }
    const [type, brand] = searchState.searchBrand.split('_');
    if (type === 'sale') {
      return dealer.relatedCarBrands.some((b) => b.toLowerCase() === brand.toLowerCase());
    }
    if (type === 'service') {
      return dealer.relatedServiceCarBrands?.some((b) => b.toLowerCase() === brand.toLowerCase());
    }
    return false;
  };

  const matchServiceSearch = (dealer: DealerForSearchType) =>
    searchState.searchService === 'Alle' ||
    dealer.relatedServices.some((service) =>
      service
        .toLowerCase()
        .includes(searchState.searchService.toLocaleString().toLocaleLowerCase()),
    );

  const matchButtonGroupFilter = (dealer: DealerForSearchType) => {
    if (!query.c) return true;
    if (query.c === BUTTON_GROUP.DEALER && !dealer.isRepairCentre) return true;
    if (query.c === BUTTON_GROUP.REPAIR_CENTRE && dealer.isRepairCentre) return true;
    if (query.c === BUTTON_GROUP.ALL) return true;

    return false;
  };

  const distance = (coords1: GeoCoordinates, coords2: GeoCoordinates) => {
    if (
      coords1.latitude === undefined ||
      coords1.longitude === undefined ||
      coords2.latitude === undefined ||
      coords2.longitude === undefined
    )
      return 0;
    const toRad = (value: number) => (value * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km
    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLon = toRad(coords2.longitude - coords1.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coords1.latitude)) *
        Math.cos(toRad(coords2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const sortByGeoLocation = (a: DealerForSearchType, b: DealerForSearchType) => {
    if (!geoCoordinates) return 0;

    const userLocation = geoCoordinates; // Example user location from the first result

    const aCoords = a.addressInfoList[0].geoCoordinates;
    const bCoords = b.addressInfoList[0].geoCoordinates;

    if (!aCoords && !bCoords) return 0;
    if (!aCoords) return 1;
    if (!bCoords) return -1;

    const aDistance = distance(userLocation, aCoords);
    const bDistance = distance(userLocation, bCoords);

    return aDistance - bDistance;
  };

  const inside50km = (dealer: DealerForSearchType) => {
    if (!geoCoordinates) return true;
    const dealerLocation = dealer.addressInfoList[0].geoCoordinates;
    if (!dealerLocation) return false;
    return distance(geoCoordinates, dealerLocation) < 50;
  };

  const matchTextSearch = (dealer: DealerForSearchType) => {
    if (!dealer) return null;

    const searchTerm = searchState.searchText.toLowerCase().trim();

    return (
      // Search in dealer title
      dealer.title.toLowerCase().includes(searchTerm) ||
      // Search in all addresses' postLocations
      dealer.addressInfoList.some((address) =>
        address.postLocation.toLowerCase().includes(searchTerm),
      )
    );
  };

  const hasDealersInRange = dealers.some(inside50km);

  const filteredDealers = dealers
    .filter(matchBrandSearch)
    .filter(matchServiceSearch)
    .filter(matchButtonGroupFilter)
    .filter((dealer) => (hasDealersInRange ? inside50km(dealer) : true))
    .filter(!geoCoordinates ? matchTextSearch : () => true)
    .sort(sortAlphabetically)
    .sort(sortByCentreType)
    .sort(sortByGeoLocation);

  const pageDefaultSEO = { ...defaultSEO, description: '', image: undefined };

  return (
    <Layout settings={settings} seo={seo} defaultSEO={pageDefaultSEO} menus={menus}>
      <Section color="primary">
        <div className="max-w-3xl">
          {overline && (
            <span className="text-tertiary mb-2 inline-block text-xl font-semibold lg:text-3xl">
              {overline}
            </span>
          )}

          <h1
            className="mb-5 text-[46px] font-semibold leading-none lg:mt-2 lg:text-6xl"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ textWrap: 'balance' }}
          >
            {title}
          </h1>
        </div>
      </Section>

      <Section>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-4 xl:grid-cols-5">
          {/* SEARCH */}
          <form onSubmit={(e) => e.preventDefault()} className="col-span-2">
            <label htmlFor="searchText" aria-label="Søk etter forhandler" className="relative">
              <input
                ref={inputRef}
                type="search"
                name="searchText"
                value={searchState.searchText}
                onChange={handleSearch}
                className="focus:ring-tertiary focus:border-tertiary placeholder-primary/50 w-full rounded-[24px] border  border-black/20 bg-white py-2 pl-12 pr-4  text-lg   duration-300 focus:rounded-md focus:outline-none focus:ring-2"
                placeholder="Søk på poststed/postnr eller navn"
              />
              <FiSearch className="peer-focus:text-tertiary absolute left-0 top-0 mx-3 block h-full text-2xl text-gray-400 duration-300" />
            </label>
          </form>

          <div className="col-span-2 grid grid-cols-2 gap-2">
            {/* BRAND SELECT */}
            <label htmlFor="searchBrand" aria-label="Filtrer på bilmerke" className="relative">
              <select
                name="searchBrand"
                value={searchState.searchBrand}
                onChange={handleSearch}
                className="focus:ring-tertiary focus:border-tertiary w-full cursor-pointer appearance-none rounded-[24px] border border-black/20 bg-white bg-clip-padding bg-no-repeat py-2 pl-4 pr-12 text-lg duration-300 focus:rounded-md focus:outline-none focus:ring-2"
              >
                <option value="Alle">Alle bilmerker</option>
                <optgroup label="Salg" className="font-semibold">
                  {!!carBrands.length &&
                    carBrands.map((option: { title: string }) => (
                      <option
                        key={option.title}
                        value={`sale_${option.title}`}
                        className="font-normal"
                      >
                        {option.title}
                      </option>
                    ))}{' '}
                  <option value="usedCars" className="font-semibold">
                    Bruktbil
                  </option>
                </optgroup>

                {!!serviceCarBrands.length && (
                  <optgroup label="Service" className="font-semibold">
                    {serviceCarBrands.map((option: { title: string }) => (
                      <option
                        key={option.title}
                        value={`service_${option.title}`}
                        className="font-normal"
                      >
                        {option.title}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
              <FiChevronDown className="peer-focus:text-tertiary text-primary/70 pointer-events-none absolute right-0 top-0 mx-3 block h-full text-2xl duration-300" />
            </label>

            {/* SERVICE SELECT */}
            <label htmlFor="searchService" aria-label="Filtrer på bilmerke" className="relative">
              <select
                name="searchService"
                value={searchState.searchService}
                onChange={handleSearch}
                className="focus:ring-tertiary focus:border-tertiary w-full cursor-pointer appearance-none rounded-[24px] border border-black/20 bg-white bg-clip-padding bg-no-repeat py-2 pl-4 pr-12 text-lg duration-300 focus:rounded-md focus:outline-none focus:ring-2"
              >
                <option value="Alle" className="bg-white">
                  Alle tjenester
                </option>
                {!!services.length &&
                  services.map((option) => (
                    <option key={option.title} value={option.title}>
                      {option.title}
                    </option>
                  ))}
              </select>
              <FiChevronDown className="peer-focus:text-tertiary text-primary/70 pointer-events-none absolute right-0 top-0 mx-3 block h-full text-2xl duration-300" />
            </label>
          </div>
        </div>

        <div className="mt-5 flex w-full flex-wrap items-end justify-between gap-4 border-b border-[#ADA9A5] md:mt-12">
          <ButtonGroup />
          <span className="inline-block pb-3 font-medium tracking-wide text-gray-600 max-md:hidden">
            Antall treff: {filteredDealers?.length || 0}
          </span>
        </div>

        <span className="mt-4 inline-block font-medium tracking-wide text-gray-600 md:hidden">
          Antall treff: {filteredDealers?.length || 0}
        </span>

        <div className="py-8">
          <ResizablePanel>
            <ListView dealers={filteredDealers} />
          </ResizablePanel>
        </div>
      </Section>
    </Layout>
  );
}

function sortAlphabetically(a: { title: string }, b: { title: string }) {
  return a.title.localeCompare(b.title, 'nb-NO');
}

function sortByCentreType(a: { isRepairCentre: boolean }, b: { isRepairCentre: boolean }) {
  return Number(a.isRepairCentre) - Number(b.isRepairCentre);
}

export const getStaticProps = async () => {
  const data = await sanityClient.fetch(DealersPageQuery);
  return {
    props: {
      data,
    },
    revalidate: 1,
  };
};

export default DealersPage;
